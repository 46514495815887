import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import Login from "./Login";
import { useMediaQuery } from 'react-responsive'
import "../../styles/RedesignV2.scss"

export default function AuthPage() {
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })

	return (
		<>
			<div className="container-auth-page" >
				<div className="section-auth-page" >
					<Switch>
						<Route path="/auth/login" component={Login} />
						<Redirect from="/auth" exact={true} to="/auth/login" />
						<Redirect to="/auth/login" />
					</Switch>
				</div>

				{!isTabletOrMobile ?
					<div className="section-auth-page" >
						<img alt="login_asset_1" src={toAbsoluteUrl("/images/login_asset_1.png")} style={{ height: '300px', position: 'absolute', top: 0, right: 0 }} />
						<img alt="login_asset_2" src={toAbsoluteUrl("/images/login_asset_2.png")} style={{ height: '150px', position: 'absolute', bottom: 0, right: '20%' }} />

						<div className="kt-grid__item kt-grid__item--middle" style={{ flex: 1, justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
							<img alt="Logo" src={toAbsoluteUrl("/images/new_logo_vertical.png")} style={{ height: '200px', objectFit: 'contain', marginBottom: '48px' }} />

							<h3 style={{ fontSize: '40px', fontFamily: 'Kumbh Sans', color: '#222222', fontWeight: 400, lineHeight: '72px', textTransform: 'uppercase', textAlign: 'center' }} >O sistema oficial da</h3>
							<h3 style={{ fontSize: '40px', fontFamily: 'Kumbh Sans', color: '#284EEA', fontWeight: 600, lineHeight: '72px', textTransform: 'uppercase', textAlign: 'center' }}>
								Barbearia por assinatura
							</h3>
						</div>
					</div>
					: null
				}
			</div>
		</>
	);
}
