import React, { Component } from "react";
import { InputLabel, TextField } from "@material-ui/core";
import { withStyles } from '@material-ui/styles';

const StyledTextField = withStyles({
	root: {
		marginTop: '8px',
		'& .MuiOutlinedInput-root': {
			height: '47px',
			// borderRadius: props => props.square ? '20px' : '24px',
			borderRadius: '6px',
			'& fieldset': {
				'& legend': {

					'& span': {
						color: '#284EEA',
					},
				},
			},
			'&.Mui-focused fieldset': {
				borderColor: '#F8B133',
			},
		},
		"& .MuiFormHelperText-root.Mui-error": {
			marginLeft: '0px',
		},
		'& .MuiOutlinedInput-multiline': {
			height: 'auto',
			minHeight: '100px',
			padding: '18.5px 14px !important',
		},
		'& .MuiInputLabel-root': {
			'&.Mui-focused': {
				color: '#F8B133',
			},
		},
		'& label.Mui-disabled': {
			color: '#53545C'
		},
		'& .Mui-disabled': {
			color: '#1B1C1F'
		},

	},
	label: {
		// textTransform: 'capitalize',
	},
})(TextField);


export default class TextInputV2 extends Component {
	render() {
		return (
			<>
				{this.props.textLabel ?
					<InputLabel style={{ fontFamily: 'Kumbh Sans', fontWeight: '500', fontSize: '14px', lineHeight: '20px', color: '#666666', marginBottom: '0px' }} >{this.props.textLabel}</InputLabel>
					: null
				}
				<StyledTextField variant="outlined"  {...this.props} />
			</>
		);
	}
} 