import { createFormikValidation } from "../../utils/Utils";

export const validateForm= (values) => {
	let map = new Map();

	map.set('adm_nome', 'nome do administrador');;
	map.set('adm_cpf', 'cpf do administrador');
	map.set('adm_data_nascimento', 'data de nascimento do administrador');
	map.set('adm_email', 'email do administrador');
	map.set('end_logradouro', 'logradouro');
	map.set('end_cidade', 'cidade');
	map.set('end_estado', 'estado');
	map.set('end_cep', 'CEP');
	map.set('end_numero', 'número');
	map.set('end_bairro', 'bairro');
	map.set('sup_telefone', 'telefone do suporte');
	map.set('sup_email', 'email do suporte');

	return createFormikValidation(map, values);
};

export const createFormData = values => {
	const formData = new FormData();

	formData.append("adm_nome", values.adm_nome);
	formData.append("adm_cpf", values.adm_cpf);
	formData.append("adm_data_nascimento", values.adm_data_nascimento);
	formData.append("adm_email", values.adm_email);
	formData.append("end_cep", values.end_cep);
	formData.append("end_logradouro", values.end_logradouro);
	formData.append("end_numero", values.end_numero);
	formData.append("end_cidade", values.end_numero);
	formData.append("end_estado", values.end_numero);
	formData.append("end_bairro", values.end_bairro);
	formData.append("sup_telefone", values.sup_telefone);
	formData.append("sup_email", values.sup_email);

	return formData;
};