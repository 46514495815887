import React, { Component } from "react";
import BetterBox from '../../components/BetterBox';
import { TextField } from "@material-ui/core";
import BetterCircularProgress from "../../components/BetterCircularProgress";
import { Formik } from "formik";
import Swal from "sweetalert2";
import { buscaCep, formatCPFInput, formatTelefoneInput, listaUfs } from "../../utils/Utils";
import { createFormData, validateForm } from "./CompletarDadosUtils";
import { create } from "../../crud/subconta.crud";
import moment from "moment";
import { Redirect } from "react-router-dom";
import BetterSelect from "../../components/BetterSelect";
import { connect } from "react-redux";
import { actions } from "../../store/ducks/flags.duck";
import BetterTooltip from "../../components/BetterTooltip";

export class CompletarDados extends Component {
	constructor() {
		super();

		this.state = {
			loading: false,
			submitted: false,
			errors: [],
			data: null,
			redirect: false
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	handleExibirCompletarConta = () => {
		this.props.setExibirCompletarConta(0);
	};

	handleRedirecionarCompletarConta = () => {
		this.props.setRedirecionarCompletarConta(0);
	};

	getInitialValues = () => {
		return {
			adm_nome: '',
			adm_cpf: '',
			adm_data_nascimento: '',
			adm_email: '',
			end_logradouro: '',
			end_numero: '',
			end_bairro: '',
			end_cidade: '',
			end_estado: '',
			end_cep: '',
			sup_telefone: '',
			sup_email: '',
		};
	};

	onSubmit = async (values) => {
		Swal.fire({
			title: 'Atenção!',
			text: 'Tem certeza que os dados estão corretos?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			cancelButtonText: "Não",
			confirmButtonText: 'Sim'
		}).then((result) => {
			if (result.value) {
				this.setState({ submitted: true });

				if (values.adm_data_nascimento >= moment().format('YYYY-MM-DD')) {
					Swal.fire('Ops', 'A data de nascimento deve ser uma data anterior ao dia de hoje.', 'error');
					this.setState({ submitted: false });

					return;
				}

				Swal.fire('Atenção', 'Não recarregue essa página enquanto enviamos seus dados.', 'warning');

				create(createFormData({ ...values })).then(res => {
					Swal.close();

					this.setState({ redirect: true }, () => {
						this.handleExibirCompletarConta();
						this.handleRedirecionarCompletarConta();
					});

				}).catch((err) => {
					if (err?.response?.data?.errors) {
						Swal.fire('Ops', err.response.data.errors[0], 'error');
					} else {
						Swal.fire('Ops', 'Ocorreu um problema ao tentar salvar. Por favor, tente novamente.', 'error');
					}

					this.setState({ submitted: false });
				}).finally(() => {
					Swal.close();
					this.setState({ submitted: false });
				})
			}
			else {
				this.setState({ submitted: false })
			}
		});
	};

	render() {
		if (this.state.redirect) {
			return <Redirect to="/" />;
		}

		return (
			<div>
				<BetterCircularProgress loading={this.state.loading}>
					<div>
						<div className="w-75" style={{ margin: '0 auto' }}>
							<h1 className="text-center" style={{ margin: '32px 0' }}>Complete o seu cadastro</h1>
							<Formik initialValues={this.getInitialValues()} validate={values => validateForm(values)} onSubmit={(values) => this.onSubmit(values)}>
								{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
									<form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
										<BetterBox title="Dados Administrador" subtitle="O administrador será responsável pela conta criada no gateway de pagamento.">
											<div className="row">
												<div className="col-sm-12">
													<div className="form-group fg-line">
														<TextField type="text" name="adm_nome" label="Nome *" margin="normal" variant="outlined"
															onBlur={handleBlur} onChange={handleChange} value={values.adm_nome} helperText={touched.adm_nome && errors.adm_nome}
															error={Boolean(touched.adm_nome && errors.adm_nome)} />
													</div>
												</div>
												<div className="col-sm-12">
													<div className="form-group fg-line">
														<TextField type="text" name="adm_cpf" label="CPF *" margin="normal" variant="outlined"
															onBlur={handleBlur} onChange={(e) => handleChange(formatCPFInput(e))} value={values.adm_cpf} helperText={touched.adm_cpf && errors.adm_cpf}
															error={Boolean(touched.adm_cpf && errors.adm_cpf)} inputProps={{ maxLength: 14 }} />
													</div>
												</div>
												<div className="col-sm-12">
													<div className="form-group fg-line">
														<TextField InputLabelProps={{ shrink: true }} type="date" name="adm_data_nascimento" label="Data nascimento *" margin="normal" variant="outlined" inputProps={{ maxLength: 15 }}
															onBlur={handleBlur} onChange={handleChange} value={values.adm_data_nascimento} helperText={touched.adm_data_nascimento && errors.adm_data_nascimento}
															error={Boolean(touched.adm_data_nascimento && errors.adm_data_nascimento)} />
													</div>
												</div>
												<div className="col-sm-12">
													<div className="form-group fg-line">
														<TextField type="email" name="adm_email" label="Email *" margin="normal" variant="outlined"
															onBlur={handleBlur} onChange={handleChange} value={values.adm_email} helperText={touched.adm_email && errors.adm_email}
															error={Boolean(touched.adm_email && errors.adm_email)} />
													</div>
												</div>

											</div>
										</BetterBox>

										<BetterBox title="Dados - Endereço da sua empresa" subtitle="Preencha todos os campos obrigatórios com o endereço da empresa.">
											<div className="row">
												<div className="col-sm-12">
													<div className="form-group fg-line">
														<TextField inputProps={{ maxLength: 9 }} name="end_cep" label="CEP *" margin="normal" variant="outlined"
															onBlur={handleBlur} onChange={(e) => handleChange(buscaCep(e, setFieldValue, 'end_logradouro', 'end_bairro', 'end_cidade', 'end_estado'))}
															value={values.end_cep} helperText={touched.end_cep && errors.end_cep}
															error={Boolean(touched.end_cep && errors.end_cep)} />
													</div>
												</div>
												<div className="col-sm-12">
													<div className="form-group fg-line">
														<TextField type="text" name="end_logradouro" label="Endereço *" margin="normal" variant="outlined"
															onBlur={handleBlur} onChange={handleChange} value={values.end_logradouro} helperText={touched.end_logradouro && errors.end_logradouro}
															error={Boolean(touched.end_logradouro && errors.end_logradouro)} />
													</div>
												</div>
												<div className="col-sm-12">
													<div className="form-group fg-line">
														<TextField type="text" name="end_numero" label="Número *" margin="normal" variant="outlined"
															onBlur={handleBlur} onChange={handleChange} value={values.end_numero} helperText={touched.end_numero && errors.end_numero}
															error={Boolean(touched.end_numero && errors.end_numero)} />
													</div>
												</div>
												<div className="col-sm-12">
													<div className="form-group fg-line">
														<TextField type="text" name="end_bairro" label="Bairro *" margin="normal" variant="outlined"
															onBlur={handleBlur} onChange={handleChange} value={values.end_bairro} helperText={touched.end_bairro && errors.end_bairro}
															error={Boolean(touched.end_bairro && errors.end_bairro)} />
													</div>
												</div>

												<div className="col-sm-12">
													<div className="form-group fg-line">
														<TextField type="text" name="end_cidade" label="Cidade *" margin="normal" variant="outlined" inputProps={{ maxLength: 255 }}
															onBlur={handleBlur} onChange={handleChange} value={values.end_cidade} helperText={touched.end_cidade && errors.end_cidade}
															error={Boolean(touched.end_cidade && errors.end_cidade)} />
													</div>
												</div>

												<div className="col-sm-12">
													<div className="form-group fg-line">
														<BetterSelect name="end_estado" label="UF *" labelWidth={25} onBlur={handleBlur} onChange={handleChange} value={values.end_estado}
															helperText={touched.end_estado && errors.end_estado} error={Boolean(touched.end_estado && errors.end_estado)}>
															{listaUfs.map((obj, i) => {
																return <option key={i} value={obj}>{obj}</option>;
															})}
														</BetterSelect>
													</div>
												</div>
											</div>
										</BetterBox>

										<BetterBox title="Dados - Suporte da Empresa" subtitle="Preencha todos os campos obrigatórios com os dados do suporte da sua barbearia.">
											<div className="row">
												<div className="col-sm-12">
													<div className="form-group fg-line">
														<TextField inputProps={{ maxLength: 15 }} type="text" name="sup_telefone" label="Telefone *" margin="normal" variant="outlined"
															onBlur={handleBlur} onChange={(e) => handleChange(formatTelefoneInput(e))} value={values.sup_telefone} helperText={touched.sup_telefone && errors.sup_telefone}
															error={Boolean(touched.sup_telefone && errors.sup_telefone)} InputProps={{
																endAdornment: (
																	<BetterTooltip title="preencher_dados_gateway_telefone_suporte" />
																)
															}} />
													</div>
												</div>
												<div className="col-sm-12">
													<div className="form-group fg-line">
														<TextField type="email" name="sup_email" label="Email *" margin="normal" variant="outlined"
															onBlur={handleBlur} onChange={handleChange} value={values.sup_email} helperText={touched.sup_email && errors.sup_email}
															error={Boolean(touched.sup_email && errors.sup_email)} InputProps={{
																endAdornment: (
																	<BetterTooltip title="preencher_dados_gateway_email_suporte" />
																)
															}} />
													</div>
												</div>
											</div>
										</BetterBox>

										<BetterBox>
											<button type="submit" disabled={this.state.submitted} className="btn btn-success btn-bold pull-right">
												<i className={`margin-icon ${this.state.submitted ? "fas fa-sync fa-spin" : "fa fa-check-square"}`}></i>Salvar
											</button>
										</BetterBox>
									</form>
								)}
							</Formik>
						</div>
					</div>
				</BetterCircularProgress>
			</div>
		);
	}
}

// Mapeia os estados do Redux para as props do componente
const mapStateToProps = (state) => ({
	exibir_completar_conta: state.flags.exibir_completar_conta,
	redirecionar_completar_conta: state.flags.redirecionar_completar_conta,
});

// Mapeia as actions para o `dispatch`
const mapDispatchToProps = {
	setExibirCompletarConta: actions.setExibirCompletarConta,
	setRedirecionarCompletarConta: actions.setRedirecionarCompletarConta,
};

// Conecta o componente ao Redux
export default connect(mapStateToProps, mapDispatchToProps)(CompletarDados);