import React, { useEffect, useState } from "react";
import MenuSection from "./MenuSection";
import MenuItemSeparator from "./MenuItemSeparator";
import MenuItem from "./MenuItem";
import { useSelector } from "react-redux";

export default function MenuList({ currentUrl, menuConfig, layoutConfig }) {
	const acessoDom = useSelector((state) => state.flags.acesso_dom);
	const [acessoGalax, setAcessoGalax] = useState(JSON.parse(window.localStorage.getItem('migrando_galax'))); 
	const [liberadoMigrar, setLiberadoMigrar] = useState(JSON.parse(window.localStorage.getItem('user_liberado_migrar')));
	const [migracaoAnalise, setMigracaoAnalise] = useState(JSON.parse(window.localStorage.getItem('migracao_analise')));
	const exibirCompletarConta = useSelector((state) => state.flags.exibir_completar_conta);

	// Estado para o menu filtrado
	const [menuConfigFiltrado, setMenuConfigFiltrado] = useState(JSON.parse(JSON.stringify(menuConfig)));

	useEffect(() => {
		const handleStorageChange = () => {
			setAcessoGalax(JSON.parse(window.localStorage.getItem('migrando_galax')));
			setLiberadoMigrar(JSON.parse(window.localStorage.getItem('user_liberado_migrar')));
			setMigracaoAnalise(JSON.parse(window.localStorage.getItem('migracao_analise')));
		};

		window.addEventListener("storage", handleStorageChange);

		return () => {
			window.removeEventListener("storage", handleStorageChange);
		};
	}, []);

	useEffect(() => {
		let novoMenuConfig = JSON.parse(JSON.stringify(menuConfig));
		console.log(exibirCompletarConta)
		if (!exibirCompletarConta) {
			console.log('teste1')
			novoMenuConfig.aside.items = novoMenuConfig.aside.items.filter(
				(item) => item.title !== "Preencher dados do Gateway"
			);
		}

		if ((!acessoDom || acessoDom == "false") && novoMenuConfig?.aside?.items) {

			novoMenuConfig.aside.items.forEach((item) => {
				if (item.title === "Painel de Assinaturas" && item.submenu) {
					// Itens a serem removidos quando acessoDom for falso
					const itensRemover = [
						"Transações financeiras",
						"Alterar assinaturas",
						"Recobrar assinaturas",
						"Migração de clientes DOM",
					];
					const paginasRemover = [
						"dashboard-gateway",
						"contratos-ativos",
					];

					// Filtrando o submenu para remover os itens especificados
					item.submenu = item.submenu.filter(
						(subItem) =>
							!itensRemover.includes(subItem.title) &&
							!paginasRemover.includes(subItem.page)
					);
				}
			});
		}

		if (!acessoGalax && acessoDom) {
			novoMenuConfig.aside.items.forEach((item) => {
				if (item.title === "Painel de Assinaturas" && item.submenu) {
					item.submenu = item.submenu.filter(
						(subItem) => subItem.title !== "Migração de clientes DOM"
					);
				}
			});
		}

		// Atualiza o estado do menu filtrado
		setMenuConfigFiltrado(novoMenuConfig);
	}, [acessoDom, acessoGalax, exibirCompletarConta, menuConfig]);

	return menuConfigFiltrado.aside.items.map((child, index) => {
		return (
			<React.Fragment key={`menuList${index}`}>
				{child.section && <MenuSection item={child} />}
				{child.separator && <MenuItemSeparator item={child} />}
				{child.simpleText && <div className="sidemenu-simpletext-v2">{child.simpleText}</div>}
				{child.title && (
					<MenuItem
						item={child}
						currentUrl={currentUrl}
						layoutConfig={layoutConfig}
					/>
				)}
			</React.Fragment>
		);
	});
}
