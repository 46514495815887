import React, { Component } from 'react';
import { OutlinedInput, InputLabel, FormControl, Select, FormHelperText } from "@material-ui/core";
import BetterTooltip from './BetterTooltip';

export default class BetterSelect extends Component {
	render() {
		return (
			<FormControl variant="outlined" className="MuiFormControl-marginNormal" error={this.props.error ? this.props.error : false}>
				<InputLabel shrink={this.props.shrinkInputLabel}>{this.props.label}</InputLabel>
				{this.props.tooltip ?
						<Select native value={this.props.value} name={this.props.name ? this.props.name : ""} disabled={this.props.disabled}
							onBlur={this.props.onBlur ? this.props.onBlur : null} onChange={this.props.onChange}
							endAdornment={
								<BetterTooltip title={this.props.tooltip} placement="top-end" />
							}
							inputProps={{ IconComponent: () => null }}
							input={<OutlinedInput name={this.props.label} labelWidth={this.props.labelWidth} />}>

							{this.props.blankOption ?
								<option />
								: null
							}

							{this.props.children}
						</Select>
					:
					<Select native value={this.props.value} name={this.props.name ? this.props.name : ""} disabled={this.props.disabled}
						onBlur={this.props.onBlur ? this.props.onBlur : null} onChange={this.props.onChange}
						input={<OutlinedInput name={this.props.label} labelWidth={this.props.labelWidth} />}>

						{this.props.blankOption ?
							<option />
							: null
						}

						{this.props.children}
					</Select>
				}

				{this.props.helperText ?
					<FormHelperText>{this.props.helperText}</FormHelperText>
					: null
				}
			</FormControl>
		);
	}
}
