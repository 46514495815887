import React, { Component } from "react";

export default function ButtonV2(props) {
	const {
		text,
		id,
		color,
		icon,
		className,
		transparent,
		outlined,
		onClick,
		loading,
		disabled
	} = props;

	return (
		<div className="button-v2">
			<button className={`btn btn-v2 ${className ?? ''} ${transparent ? 'btn-v2-transparent' : ''} ${outlined ? 'btn-v2-outlined btn-v2-outlined-' + color : 'btn-v2-' + color}`}
				onClick={onClick} id={id} disabled={disabled} >
				{loading ?
					<i className="fas fa-sync fa-spin" />
					:
					(icon ?
						<i className={props.icon} />
						:
						null
					)
				}
				{text}
			</button>
		</div>
	);

}