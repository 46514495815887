/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import objectPath from "object-path";
import KTToggle from "../../_assets/js/toggle";
import * as builder from "../../ducks/builder";
import { toAbsoluteUrl } from "../../../_metronic/utils/utils";


class Brand extends React.Component {
	ktToggleRef = React.createRef();

	componentDidMount() {
		// eslint-disable-next-line no-undef
		new KTToggle(this.ktToggleRef.current, this.props.toggleOptions);
	}

	render() {
		return (
			<div className={`kt-aside__brand kt-grid__item ${this.props.brandClasses}`} id="kt_aside_brand"
			style={{ margin: '16px 0px', padding: '0px 10px 0px 32px' }} >
				<div className="kt-aside__brand-logo">
					<Link to="/">
						<img alt="logo" src={toAbsoluteUrl("/images/new_logo.png")} style={{ width: '180px' }} />
					</Link>
				</div>

				{this.props.asideSelfMinimizeToggle && (
					<div className="kt-aside__brand-tools">
						<button
							ref={this.ktToggleRef}
							className="kt-aside__brand-aside-toggler"
							id="kt_aside_toggler"
						>
							<span>
								<div style={{ border: 'solid 1px #B8C6FF', padding: '6px 8px', borderRadius: 6, margin: 4, color: '#284EEA', display: 'flex', alignItems: 'center' }}>
									<i class={'fa fa-arrow-left'} style={{ fontSize: 16 }} ></i>
								</div>
							</span>
							<span>
								<div>
									<img alt="logo" src={toAbsoluteUrl("/images/new_logo_icon.png")} style={{ width: 35, marginLeft: '-21px' }} />
								</div>
							</span>
						</button>
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = store => {
	return {
		brandClasses: builder.selectors.getClasses(store, {
			path: "brand",
			toString: true
		}),
		asideSelfMinimizeToggle: objectPath.get(
			store.builder.layoutConfig,
			"aside.self.minimize.toggle"
		),
		headerLogo: builder.selectors.getLogo(store),
		headerStickyLogo: builder.selectors.getStickyLogo(store),
		toggleOptions: {
			target: "body",
			targetState: "kt-aside--minimize",
			togglerState: "kt-aside__brand-aside-toggler--active"
		}
	};
};

export default connect(mapStateToProps)(Brand);
