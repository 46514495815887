import React from "react";
import { useLocation, Link } from 'react-router-dom';
import store from "../../../../app/store/store";
import { useSelector } from "react-redux";

export default function BreadCrumbV2(props) {
	const breadcrumb = useSelector((state) => state.breadcrumb.breadcrumb);


	if (!breadcrumb || !breadcrumb.length) {
		return <p></p>;
	}

	const length = breadcrumb.length;

	return (
		<div className="header-breadcrumb">
			{breadcrumb.map((item, index) => (
				item.path ? (
					<Link to={item.path} key={index}>
						<p className={`${length === index + 1 ? 'breadcrumb-current-page' : 'breadcrumb-path'}`}>{item.label}</p>
					</Link>
				) : (
					<p key={index} className={`${length === index + 1 ? 'breadcrumb-current-page' : 'breadcrumb-path'}`}>{item.label}</p>
				)
			))}
		</div>
	);
}