/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React, { useEffect } from "react";
import { Redirect, Route, Switch, withRouter, useLocation, useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import HomePage from "../pages/home/HomePage";
import ErrorsPage from "../pages/errors/ErrorsPage";
import LogoutPage from "../pages/auth/Logout";
import { LayoutContextProvider } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import * as routerHelpers from "../router/RouterHelpers";
import AuthPage from "../pages/auth/AuthPage";
import ForgotPassword from "../pages/password/ForgotPassword";
import ResetPassword from "../pages/password/ResetPassword";
import TermoDeUso from "../pages/termos-uso/TermosUso";
import PoliticaDePrivacidade from "../pages/politica-privacidade/PoliticaPrivacidade";
import TelaFilaAgendamentoIndex from "../pages/fila-exibicao-agendamentos/TelaFilaAgendamentosIndex";
import CompletarDados from "../pages/subconta/CompletarDados";
import { actions } from "../store/ducks/flags.duck";

export const Routes = withRouter(({ history }) => {
	const lastLocation = useLastLocation();
	const location = useLocation();
	const url = location.pathname;

	routerHelpers.saveLastLocation(lastLocation);
	const { isAuthorized, menuConfig, userLastLocation } = useSelector(
		({ auth, urls, builder: { menuConfig }, flags }) => ({
			menuConfig,
			isAuthorized: auth.user != null,
			userLastLocation: routerHelpers.getLastLocation(),
			contaAutorizadaAMigrar: auth.user?.empresa.emp_migracao_gateway_liberada == 1 && auth.user?.empresa?.emp_subconta_dom_completa == 0 && auth.autorizado_migrar == auth.user?.id,
			contaUsaGalax: auth.user?.empresa.emp_galax_pay_public_token != null && auth.user?.empresa.emp_galax_pay_public_token != ""
		})
	);
	
	const exibirCompletarConta = useSelector((state) => state.flags.exibir_completar_conta);
	const redirecionarCompletarConta = useSelector((state) => state.flags.redirecionar_completar_conta);

	const acessoDom = JSON.parse(window.localStorage.getItem('acesso_dom'));

	const completouMigracao = JSON.parse(window.localStorage.getItem('completou_migracao'));
	const pendenteDocumentos = JSON.parse(window.localStorage.getItem('pendente_documentos'));

	useEffect(( ) => {
		console.log(location.pathname)
	}, []);

	return (
		/* Create `LayoutContext` from current `history` and `menuConfig`. */
		<LayoutContextProvider history={history} menuConfig={menuConfig}>
			<Switch>
				<Route path="/fila-agendamentos/:id" component={TelaFilaAgendamentoIndex} />
				<Route path="/error" component={ErrorsPage} />
				<Route path="/logout" component={LogoutPage} />

				{exibirCompletarConta ?
					<Route path="/completar-conta" component={CompletarDados} />
					: null
				}

				{redirecionarCompletarConta && isAuthorized ?
					<Redirect to="/completar-conta" />
					: null
				}

				{!isAuthorized && useLocation().pathname === '/password/forgot' ?
					<Route path="/password/forgot" component={ForgotPassword} />
					: null
				}

				{!isAuthorized && useLocation().pathname === '/password/reset' ?
					<Route path="/password/reset" component={ResetPassword} />
					: null
				}

				{!isAuthorized && useLocation().pathname === '/termos-de-uso' ?
					<Route path="/termos-de-uso" component={TermoDeUso} />
					: null
				}

				{!isAuthorized && useLocation().pathname === '/politica-de-privacidade' ?
					<Route path="/politica-de-privacidade" component={PoliticaDePrivacidade} />
					: null
				}

				{!isAuthorized ? (
					/* Render auth page when user at `/auth` and not authorized. */
					<AuthPage />
				) : (
					/* Otherwise redirect to root page (`/`) */
					<Redirect from="/auth" to={userLastLocation} />
				)}

				{!isAuthorized ? (
					/* Redirect to `/auth` when user is not authorized */
					<Redirect to="/auth/login" />
				) : (
					<Layout>
						<HomePage userLastLocation={userLastLocation} />
					</Layout>
				)}
			</Switch>
		</LayoutContextProvider>
	);
});
