import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  SetExibirCompletarConta: "[Completar Conta] Set Exibir",
  SetRedirecionarCompletarConta: "[Completar Conta] Set Redirecionar",
  SetAcessoDom: "Set acesso dom"
};

const initialState = {
  exibir_completar_conta: false,
  redirecionar_completar_conta: false,
  acesso_dom: localStorage.getItem("acesso_dom")
};

export const reducer = persistReducer(
  { storage, key: "app-completar-conta", whitelist: ["exibir_completar_conta", "redirecionar_completar_conta", "acesso_dom"] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SetExibirCompletarConta: {
        return {
          ...state,
          exibir_completar_conta: action.payload,
        };
      }

      case actionTypes.SetRedirecionarCompletarConta: {
        return {
          ...state,
          redirecionar_completar_conta: action.payload,
        };
      }

	  case actionTypes.SetAcessoDom: {
		return {
			...state,
			acesso_dom: action.payload
		}
	  }

      default:
        return state;
    }
  }
);

export const actions = {
  setExibirCompletarConta: (value) => ({ type: actionTypes.SetExibirCompletarConta, payload: value }),
  setRedirecionarCompletarConta: (value) => ({ type: actionTypes.SetRedirecionarCompletarConta, payload: value }),
  setAcessoDom: (value) => ({ type: actionTypes.SetAcessoDom, payload: value })
};

export function* saga() {}