import React from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import * as builder from "../../ducks/builder";
import { Constants } from "../../../app/utils/Constants";
import "../../_assets/sass/custom/footer-custom.scss"

class Footer extends React.Component {
	render() {
		const today = new Date().getFullYear();
		return (
			<div className={`kt-footer ${this.props.footerClasses} kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop`} id="kt_footer">
				<div className={`kt-container ${this.props.footerContainerClasses}`}>
					<div className="footer-container">
						<div className="footer-copyright d-flex">
							{today.toString()}&nbsp;&copy;&nbsp;
							<a href="https://cashbarber.com.br/" target="_blank" rel="noopener noreferrer" className="kt-link">Cashbarber</a>
						</div>
						<div className="footer-links">
							<a target='_blank' href={Constants.termosUsoURL}>Termos de uso</a>
							<a target='_blank' href={Constants.politicasPrivacidadeURL}>Políticas de privacidade</a>
							<a target='_blank' href={Constants.logsVersoesURL}>Atualizações</a>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = store => ({
	fluid:
		objectPath.get(store.builder.layoutConfig, "footer.self.width") === "fluid",
	footerClasses: builder.selectors.getClasses(store, {
		path: "footer",
		toString: true
	}),
	footerContainerClasses: builder.selectors.getClasses(store, {
		path: "footer_container",
		toString: true
	})
});

export default connect(mapStateToProps)(Footer);
