import axios from 'axios';
import moment from 'moment-timezone';
import * as utils from "../../_metronic/utils/utils";
import { formatFormaPagamento } from '../pages/home/relatorio/RelatorioUtils';

export const createFormikValidation = (formMap, values) => {
	const errors = {};

	for (let [key, value] of formMap) {
		if (!values[key] && values[key] !== 0) {
			errors[key] = "O campo " + value + " é obrigatório."
		} else if ((key === 'email' || key ===  'adm_email' || key ===  'sup_email') && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values[key])) {
			errors[key] = "Você precisa informar um e-mail válido.";
		} else if ((key === 'cli_cpf' || key === 'adm_cpf') && !validaCpf(values[key])) {
			errors[key] = "O CPF informado é inválido.";
		} else if (key === 'password' && values[key].length < 6) {
			errors[key] = "A senha deve ter pelo menos 6 caracteres.";
		}
	}

	return errors;
};

export const validaCpf = (cpf) => {
	var add = 0;
	var rev = 0;

	cpf = cpf.replace(/[^\d]+/g, '');
	if (cpf == '') return false;

	if (cpf.length != 11) return false;

	for (var i = 0; i < 9; i++) {
		add += parseInt(cpf.charAt(i)) * (10 - i);
	}

	rev = 11 - (add % 11);

	if (rev == 10 || rev == 11) {
		rev = 0;
	}
	if (rev != parseInt(cpf.charAt(9))) {
		return false;
	}

	add = 0;

	for (var j = 0; j < 10; j++) {
		add += parseInt(cpf.charAt(j)) * (11 - j);
	}

	rev = 11 - (add % 11);

	if (rev == 10 || rev == 11) {
		rev = 0;
	}

	if (rev != parseInt(cpf.charAt(10))) {
		return false;
	}

	return true;
};

export const shallowEqual = (object1, object2) => {
	const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (let key of keys1) {
		if (object1[key] !== object2[key]) {
			return false;
		}
	}

	return true;
};

export const getTimeIntervalInMinutes = (inicio, fim) => {
	var inicio = inicio;
	var fim = fim;

	var difference = moment(inicio, "HH:mm").diff(moment(fim, "HH:mm"));
	var diferrenceInMs = moment.duration(difference);
	var interval = Math.abs(Math.floor(diferrenceInMs.asMinutes()));

	return interval;
}

export const formatHourInput = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{2})(\d)/, "$1:$2");

	input.target.value = value;

	return input;
};

export const formatCNPJInput = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{2})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d)/, "$1/$2");
	value = value.replace(/(\d{4})(\d{1,2})$/, "$1-$2");

	input.target.value = value;

	return input;
};

export const formatCPFInput = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{3})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

	input.target.value = value;

	return input;
};

export const formatCPFouCNPJInput = input => {
	let value = input.target.value;

	if (value.length > 14) {
		value = value.replace(/\D/g, "");
		value = value.replace(/(\d{2})(\d)/, "$1.$2");
		value = value.replace(/(\d{3})(\d)/, "$1.$2");
		value = value.replace(/(\d{3})(\d)/, "$1/$2");
		value = value.replace(/(\d{4})(\d{1,2})$/, "$1-$2");
	} else {
		value = value.replace(/\D/g, "");
		value = value.replace(/(\d{3})(\d)/, "$1.$2");
		value = value.replace(/(\d{3})(\d)/, "$1.$2");
		value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
	}

	input.target.value = value;

	return input;
}

export const formatDataInput = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{2})(\d)/, "$1/$2");
	value = value.replace(/(\d{2})(\d)/, "$1/$2");

	input.target.value = value;

	return input;
};

export const formatTelefoneInput = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{0})(\d)/, "$1($2");
	value = value.replace(/(\d{2})(\d)/, "$1) $2");
	value = value.replace(/(\d{1})(\d{1,4})$/, "$1-$2");

	input.target.value = value;

	return input;
};

export const formatCepInput = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{5})(\d)/, "$1-$2");

	input.target.value = value;

	return input;
};

export const formatBRLInput = input => {
	input.target.value = Number(input.target.value.toString().replace(/[^0-9]/g, '')) / 10 ** 2;

	input.target.value = formatBRL(input.target.value);

	return input;
};

export const formatBRL = value => {
	const formatter = new Intl.NumberFormat('pt-BR', {
		style: 'currency',
		currency: 'BRL',
		minimumFractionDigits: 2
	});

	value = formatter.format(value);

	return value;
};

export const formatIntegerInput = input => {
	if (input.target.value == '') {
		input.target.value = '';
		return input;
	}

	input.target.value = Number(input.target.value.toString().replace(/[^0-9]/g, ''));
	return input;
};

export const formatPercentageInput = input => {
	if (input.target.value == '') input.target.value = 0;
	input.target.value = Number(input.target.value.toString().replace(/[^0-9]/g, ''));
	if (input.target.value == 0) input.target.value = 0;
	if (input.target.value > 100) input.target.value = '100';
	return input;
};

export const formatPercentageFloatInput = (input) => {
	if (!input.target.value) {
		return '';
	}

	let value = parseFloat(input.target.value.toString().replace(/[^0-9]/g, ''));

	if (isNaN(value)) {
		input.target.value = '';

		return input;
	}

	value = value / 100;

	if (value === 0 && input.target.value.startsWith('0')) {
		value = 0;
	}

	if (isNaN(value)) {
		throw new Error('Invalid input: Please enter a valid number.');
	}

	if (value < 0) {
		throw new Error('Percentage cannot be negative.');
	}

	if (value > 100) {
		value = 100;
	}

	input.target.value = value.toFixed(2);
	input.target.value = input.target.value.replace('.', ',');

	return input;
};

export const clearPercentage = (value) => {
	return value.replace('%', '').replace(',', '.');
};

export const buscaCep = (input, setFieldValue, nomeEndereco, nomeBairro, nomeCidade, nomeUf) => {
	if (input.target.value.length > 8) {
		var cep = input.target.value.replace(/[^0-9]/, "");

		const instance = axios.create();

		instance.get(`https://viacep.com.br/ws/${cep}/json/`, {}).then(res => {

			if (res.data.erro) {
				setFieldValue(nomeEndereco, '');
				setFieldValue(nomeBairro, '');
				setFieldValue(nomeCidade, '');
				setFieldValue(nomeUf, "AC");
			} else {
				setFieldValue(nomeEndereco, res.data.logradouro);
				setFieldValue(nomeBairro, res.data.bairro);
				setFieldValue(nomeCidade, res.data.localidade);
				setFieldValue(nomeUf, res.data.uf);
			}
		}).catch(err => {
		});
	}

	return formatCepInput(input);
};

export const limparMoeda = (valor) => {
	valor = valor.replace("R$", "");
	valor = valor.replace(".", "");
	valor = valor.replace(",", ".");
	// eslint-disable-next-line
	valor = valor.replace('/^\p{Z}+|\p{Z}+$/u', '');
	valor = valor.trim();

	return valor
};

export const listaUfs = [
	'AC',
	'AL',
	'AM',
	'AP',
	'BA',
	'CE',
	'DF',
	'ES',
	'GO',
	'MA',
	'MG',
	'MS',
	'MT',
	'PA',
	'PB',
	'PE',
	'PI',
	'PR',
	'RJ',
	'RN',
	'RO',
	'RR',
	'RS',
	'SC',
	'SE',
	'SP',
	'TO'
];

export const listaCategoriasEmpresasParceiras = [
	{
		label: "Vestuário",
		value: "VESTUARIO"
	},
	{
		label: "Gastronomia",
		value: "GASTRONOMIA"
	},
	{
		label: "Conveniência",
		value: "CONVENIENCIA"
	},
	{
		label: "Sáude / Estética",
		value: "SAUDEESTETICA"
	},
	{
		label: "Serviços",
		value: "SERVICOS"
	},
	{
		label: "Esportes",
		value: "ESPORTES"
	},
	{
		label: "Limpeza",
		value: "LIMPEZA"
	}
]

export const displayValueCurrency = (value) => {
	return value ? `R$ ${value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}` : "Não disponível";
};

export const displayValueFixed = (value, casas) => {
	return value ? value.toFixed(casas) : "Não disponível";
};

export const displayValuePercent = (value) => {
	return value ? `${value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })} %` : "Não disponível";
};

export const listControllers = {
	'App\\Http\\Controllers\\Empresa\\AuthController': "Autenticação",
	'App\\Http\\Controllers\\Empresa\\UserGroupController': "Grupo de permissões",
	'App\\Http\\Controllers\\Empresa\\DashboardController': "Dashboard",
	'App\\Http\\Controllers\\Empresa\\AgendamentoController': "Agendamento",
	'App\\Http\\Controllers\\Empresa\\CaixaController': "Caixa",
	'App\\Http\\Controllers\\Empresa\\ComandaController': "Comanda",
	'App\\Http\\Controllers\\Empresa\\ComandaProdutoController': "Bar/Cozinha",
	'App\\Http\\Controllers\\Empresa\\PlanoController': "Plano",
	'App\\Http\\Controllers\\Empresa\\ServicoController': "Serviço",
	'App\\Http\\Controllers\\Empresa\\ProdutoController': "Produto",
	'App\\Http\\Controllers\\Empresa\\FilialController': "Filial",
	'App\\Http\\Controllers\\Empresa\\ClienteController': "Cliente",
	'App\\Http\\Controllers\\Empresa\\RelatorioController': "Relatório",
	'App\\Http\\Controllers\\Empresa\\UsuarioController': "Usuário",
	'App\\Http\\Controllers\\Empresa\\PerfilController': "Perfil",
	'App\\Http\\Controllers\\Empresa\\EstoqueController': "Estoque",
	'App\\Http\\Controllers\\Empresa\\CategoriaController': "Categoria",
	'App\\Http\\Controllers\\Empresa\\ClubeEmpresasParceirasController': "Empresas parceiras",
	'App\\Http\\Controllers\\Empresa\\ClientePlanoPreAprovadoController': "Cliente pré aprovado",
	'App\\Http\\Controllers\\Empresa\\ClientePlanoPreCanceladoController': "Cliente plano pré cancelado",
	'App\\Http\\Controllers\\Empresa\\EmpresaController': "Empresa",
	'App\\Http\\Controllers\\Empresa\\HistoricoCaixasController': "Histórico de caixas",
	'App\\Http\\Controllers\\Empresa\\HorarioFeriadosController': "Horários de feriados",
	'App\\Http\\Controllers\\Empresa\\ClienteBloqueioController': "Bloqueio de clientes",
	'App\\Http\\Controllers\\Empresa\\DpoteController': "Dpote",
	'App\\Http\\Controllers\\Empresa\\PumpController': "Pump",
	'App\\Http\\Controllers\\Empresa\\ClienteRecompraController': "Recompras de clientes",
	'App\\Http\\Controllers\\Empresa\\MensagemConfirmacaoAgendamentoController': "Confirmação WhatsApp",
	'App\\Http\\Controllers\\Empresa\\ContaBancariaController': "Contas bancárias",
	'App\\Http\\Controllers\\Empresa\\CategoriaFinanceiraController': "Categoria financeira",
	'App\\Http\\Controllers\\Empresa\\FormaPagamentoController': "Forma de pagamentos",
	'App\\Http\\Controllers\\Empresa\\MovimentacaoController': "Movimentações",
	'App\\Http\\Controllers\\Empresa\\ComissaoController': "Comissão",
	'App\\Http\\Controllers\\Empresa\\MensagemConfirmacaoAgendamentoController': "Configurações de WhatsApp",
	'App\\Http\\Controllers\\Empresa\\ComunicadoController': "Comunicados",
	'App\\Http\\Controllers\\Empresa\\GoogleApiController': "Api Google"
};

export const listFunctions = {
	"auth": "Entrar",
	"forgotPassword": "Recuperar senha",
	"resetPassword": "Trocar senha",
	"store": "Cadastrar",
	"update": "Atualizar",
	"get": "Visualizar",
	"delete": "Apagar",
	"getDashboard": "Dados da dashboard",
	"getTaxaOcupacao": "Dados da taxa de ocupação",
	"addProdutos": "Adicionar produtos",
	"addServicos": "Adicionar serviços",
	"deleteProdutos": "Remover produtos",
	"deleteServicos": "Remover serviços",
	"addAgendamentos": "Adicionar agendamento",
	"cancelarAgedamento": "Cancelar agendamento",
	"entregar": "Entregar produto",
	"abrirCaixa": "Abrir caixa",
	"fecharCaixa": "Fechar caixa",
	"getComandas": "Comandas no caixa",
	"simpleList": "Lista simples",
	"list": "Listar",
	"listEmOrdem": "Listar em Ordem",
	"listServicos": "Listar serviços",
	"simpleListBarbeiro": "Listar profissionais",
	"destroy": "Apagar",
	"getPermissions": "Listar permissões",
	"getDadosFechamento": "Dados para fechamento de caixa",
	"simpleListDiarioByFilial": "Listar agendamentos diário por filial",
	"cancelarAgendamento": "Cancelar",
	"finalizarAgendamento": "Finalizar",
	"fecharComanda": "Fechar",
	"addProduto": "Adicionar produtos",
	"addServico": "Adicionar serviços",
	"deleteProduto": "Remover produtos",
	"deleteServico": "Remover serviços",
	"addAgendamento": "Adicionar agendamento",
	"deleteAgendamento": "Remover agendamentos",
	"getServicoPreco": "Buscar valores de serviço",
	"getRelatorio01": "Financeiro",
	"getRelatorio02": "Comissões de serviços",
	"getRelatorio03": "Agendamentos",
	"getRelatorio04": "Planos",
	"getRelatorio05": "Clientes",
	"getRelatorio06": "Avaliações",
	"getRelatorio08": "Ticket médio do profissional",
	"getRelatorio09": "Cliente por período e serviço",
	"getRelatorio10": "Serviços",
	"getRelatorio11": "Captação de clientes",
	"getRelatorio12": "Estoques",
	"getRelatorio13": "Comissão de produto",
	"getRelatorio14": "Aniversariantes por período",
	"getRelatorio15": "Vendas",
	"getRelatorio16": "Clientes ausentes",
	"getRelatorio17": "Novos clientes",
	"getRelatorio18": "Formas de pagamento",
	"getRelatorio19": "Assinaturas de planos",
	"getRelatorio20": "Faturamento",
	"getRelatorio21": "Cancelados por período",
	"entregarProduto": "Entregar produto",
	"deletePlano": "Cancelar plano",
	"manualPayment": "Adicionar pagamento manual",
	"changeClienteStatus": "Confirmar / Cancelar Agendamento",
	"getCreditCard": "Verificar cartão cadastrado",
	"resizeUpdate": "Alterar data e hora do agendamento.",
	"getProdutoPreco": "Buscar preço do produto",
	"cancelarIntervalo": "Cancelar intervalo",
	"cancelarFolga": "Cancelar folga",
	"addCupom": "Adicionar cupom",
	"removeCupom": "Remover cupom",
	"updateCupom": "Editar cupom",
	"addBanner": "Adicionar banner",
	"removeBanner": "Remover banner",
	"updateBanner": "Editar banner",
	"getBanner": "Dados de um banner",
	"listBanner": "Listar banners",
	"getRelatorioCupons": "Relatório Cupons",
	"vincularGalaxPay": "Criar plano para vincular ao GalaxPay",
	"deletePlanoOnlyBarber": "Cancelar plano apenas no CashBarber",
	"createCliente": "Criar cliente",
	"handleIntervalos": "Gerenciar intervalos",
	"listCartoesAtivos": "Listar cartões ativos",
	"importarCartoesGalax": "Importar cartões do Galax",
	"destroyCartao": "Excluir cartão",
	"storeCartao": "Adicionar cartão",
	"storeNovoPlano": "Cadastrar plano",
	"createNewCreditCard": "Criar novo cartão de crédito",
	"updatePaymentMethod": "Atualizar forma de pagamento",
	"updatePayment": "Atualizar pagamento",
	"atualizarIdIntegracao": "Atualizar ID de integração do plano",
	"getCategorias": "Buscar categorias",
	"editServico": "Editar serviço",
	"getServicos": "Serviços da  comanda",
	"checkConflictBeforeStore": "Verificar de conflito de horários",
	"newMovement": "Nova movimentação",
	"getRegistros": "Listar comandas",
	"reabrirComanda": "Reabrir comanda",
	"listCaixaMovimentacoes": "Listar abertura/fechamento de caixas",
	"listCaixaMovimentacoesRegistros": "Listar movimentações de caixas",
	"simpleListComPlano": "Listagem com plano",
	"storeAgendamentoConsumo": "Criar comanda de consumo",
	"simpleListBarbeirosAtivos": "Listar profissionais ativos",
	"externalPay": "Informar pagamento externo",
	"enableAccessToPlan": "Habilitar acesso ao plano",
	"listUserServicosById": "Listar Serviços do Profissional",
	"simpleListComandasAgenda": "Listar comandas na agenda",
	"getHistorico": "Listar histórico completo do cliente",
	"getHistoricoCurto": "Listar últimos agendamentos do cliente",
	"atualizarDataInicioPlano": "Atualizar data de início do plano",
	"listAll": "Listar todos",
	"setExibicaoServicos": "Ordenar e destacar serviços",
	"listOrdenada": "Listar profissionais",
	"setExibicaoProfissionais": "Ordenar e destacar profissionais",
	"setExibicaoServicos": "Ordenar e destacar serviços",
	"verificarAcesso": "Verificar Acesso",
	"getRelatorio": "Relatório",
	"storeHistorico": "Salvar histórico",
	"storeHistoricoViaRelatorio": "Criar histórico via relatório",
	"listHistorico": "Listar históricos",
	"getLastIdHistorico": "Último histórico",
	"getHistorico": "Acessar histórico",
	"updateHistorico": "Atualizar histórico",
	"destroyHistorico": "Deletar histórico",
	"verificarAcessoDpote": "Verificar acesso ao Dpote",
	"getBarbeiros": "Listar profissionais",
	"listEmOrdem": "Listar em ordem",
	"storeComandaAgendamento": "Cadastrar comandas via agenda",
	"getComunicados": "Listar comunicados",
	"interacaoComunicado": "Interação com comunicados",
	"getMensagemByAgendamento": "Gerar mensagem",
	"pagarComissoes": "Pagar comissões",
	"marcarComoPago": "Marcar como pago",
	"getMensagemPlanoPreAprovado": "Gerar mensagem plano pré-aprovado",
	"getMensagemAniversario": "Gerar mensagem de aniversário",
	"getMensagemByAgendamento": "Gerar mensagem",
	"removeToken": "Remover token",
	"sincronizarGalax": "Sincronização com o galax",
	"listaOrdenada": "Visualizar ordenação",
	"setExibicaoPlanos": "Destacar/Editar a ordenação",
	"registerPush": "Registrar push",
	"deletePush": "Remover push",
	"getBalanco": "Balanço financeiro",
	"entregarPresentes": "Entregar presente",
	"checarPresentes": "Checar presente"
};

export const getProdutoCategorias = [
	{ key: "COSMETICO", value: "Cosmético" },
	{ key: "BAR", value: "Bar" },
	{ key: "COZINHA", value: "Cozinha" },
	{ key: "OUTROS", value: "Outros" },
];

export const getCategoriasTypes = [
	{ key: "PRODUTO", value: "Produto" },
	{ key: "SERVICO", value: "Serviço" },
]

export const getWeekDays = [
	{
		label: 'Segunda',
		key: 'Segunda'
	},
	{
		label: 'Terça',
		key: 'Terça'
	},
	{
		label: 'Quarta',
		key: 'Quarta'
	},
	{
		label: 'Quinta',
		key: 'Quinta'
	},
	{
		label: 'Sexta',
		key: 'Sexta'
	},
	{
		label: 'Sábado',
		key: 'Sábado'
	},
	{
		label: 'Domingo',
		key: 'Domingo'
	}
];

export const getJanelasAtendimento = [15, 20, 30, 40, 60];

export const getPeriodosAtendimento = [7, 15, 21, 30];

export const getPeriodosBloqueioPlano = [30, 60, 90];

export const getUserTypes = [
	{ key: "ADMIN", value: "Admin" },
	{ key: "BARBEIRO", value: "Profissional" },
];

export const getEstoqueType = [
	{ key: "ENTRADA", value: "Entrada" },
	{ key: "SAIDA", value: "Saída" },
];

export const getFormasPagamento = [
	{ key: "CARTAO_CREDITO", value: "Cartão de crédito" },
	{ key: "CARTAO_DEBITO", value: "Cartão de débito" },
	{ key: "DINHEIRO", value: "Dinheiro" },
	{ key: "PIX", value: "PIX" },
	{ key: "VALE_PRESENTE", value: "Vale presente" },
	{ key: "CORTESIA", value: "Cortesia" },
	{ key: "CONVENIO", value: "Convênio" },
	{ key: "OUTROS", value: "Outros" },
	{ key: "GALAXPAY", value: "Galax pay" }
];

export const getFormasPagamentoBetter = (forma_pagamento) => {
	const find = getFormasPagamento.find(item => item.key == forma_pagamento);

	if (find) {
		return find.value
	} else {
		return "N/A"
	}
};

export const getFormasPagamentoShort = [
	{ key: "CARTAO_CREDITO", value: "Crédito" },
	{ key: "CARTAO_DEBITO", value: "Débito" },
	{ key: "CORTESIA", value: "Cortesia" },
	{ key: "VALE_PRESENTE", value: "Vale presente" },
	{ key: "GALAXPAY", value: "Galax pay" },
	{ key: "DINHEIRO", value: "Dinheiro" },
	{ key: "CONVENIO", value: "Convênio" },
	{ key: "OUTROS", value: "Outros" },
	{ key: "PIX", value: "PIX" },
];

export const listAssinaturasStatus = [
	{ value: "captured", label: "Aprovado (captured)" },
	{ value: "denied", label: "Negada (denied)" },
	{ value: "processando", label: "Processando (authorized/not-send)" },
	{ value: "reversed", label: "Estornada (reversed)" },
];

export const unicodeToChar = (text) => {
	return text.replace(/\\u[\dA-F]{4}/gi,
		function (match) {
			return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
		});
}

export const comoConheceuList = [
	"Facebook",
	"Fachada da loja",
	"Google",
	"Indicação",
	"Instagram",
	// "Outros",
	"Panfleto",
	"Site"
]

export function getTipoFormatted(tipo) {
	if (tipo == 'CREDITO') {
		return 'Crédito';
	}
	else {
		return 'Débito';
	}
};

/**
 * Recebe o fuso horário no formato GMT e converte para uma timezone reconhecido do PHP
 * @param {*}  
 * @returns um timezone reconhecido pelo PHP
 */
export const getTimeZone = () => {
	switch (localStorage.getItem('timeZone')) {
		case 'GMT-2':
			return 'America/Noronha';
		case 'GMT-4':
			return 'America/Manaus';
		case 'GMT-5':
			return 'America/Rio_Branco';
		default:
			return 'America/Sao_Paulo';
	}
};

/**
 * Converte uma data para o GMT correto.
 * @param {*} date - O dia, (caso o formato original seja um datetime passar apenas esse parâmetro e o time como null)
 * @param {*} time - O horário 
 * @param {*} format - O formato que deseja ser retornado
 * @param {*} toGMT3 - se true a data será convertida para gmt-3 se false ela irá ser convertida de GMT-3 para a timezone do usuário
 * @returns - A data formatada no GMT correto.
 */
export const convertToGMT = (date, time, format, toGMT3) => {

	let f_datetime = moment();

	if (date && time) {
		f_datetime = moment(date + ' ' + time);
	} else if (date) {
		f_datetime = moment(date);
	}

	if (toGMT3) {
		switch (localStorage.getItem('timeZone')) {
			case 'GMT-2':
				f_datetime.subtract(1, 'hour');
				break;
			case 'GMT-4':
				f_datetime.add(1, 'hour');
				break;
			case 'GMT-5':
				f_datetime.add(2, 'hours');
				break;
			default:
				break;
		}
	} else {
		switch (localStorage.getItem('timeZone')) {
			case 'GMT-2':
				f_datetime.add(1, 'hour');
				break;
			case 'GMT-4':
				f_datetime.subtract(1, 'hour');
				break;
			case 'GMT-5':
				f_datetime.subtract(2, 'hours');
				break;
			default:
				break;
		}
	}

	return f_datetime.format(format)
};

export const converterHoraParaFusoHorario = (hora, fusoHorario) => {
	const [horas, minutos] = hora.split(':');
	let horaMoment = moment.utc().hours(horas).minutes(minutos);

	switch (fusoHorario) {
		case 'GMT-2':
			horaMoment = horaMoment.add(1, 'hour');
			break;
		case 'GMT-4':
			horaMoment = horaMoment.subtract(1, 'hour');
			break;
		case 'GMT-5':
			horaMoment = horaMoment.subtract(2, 'hours');
			break;
		default:
			break;
	}

	const horaConvertida = horaMoment.format('HH:mm');
	return horaConvertida;
};

export const converterHoraParaGMT3 = (hora, fusoHorario) => {
	const [horas, minutos] = hora.split(':');
	let horaMoment = moment.utc().hours(horas).minutes(minutos);

	switch (fusoHorario) {
		case 'GMT-2':
			horaMoment = horaMoment.subtract(1, 'hour');
			break;
		case 'GMT-4':
			horaMoment = horaMoment.add(1, 'hour');
			break;
		case 'GMT-5':
			horaMoment = horaMoment.add(2, 'hours');
			break;
		default:
			break;
	}

	const horaConvertida = horaMoment.format('HH:mm');
	return horaConvertida;
};

export const arrayColumn = (array, column) => {
	return array.map(item => item[column]);
};

export const useDebounce = (functionToUse, timeInMilliseconds = 500) => {
	let timeout;

	return (...params) => {
		const delayFunction = () => {
			clearTimeout(timeout);

			if (params) {
				functionToUse(...params);
			} else {
				functionToUse();
			}
		};
		clearTimeout(timeout);
		timeout = setTimeout(delayFunction, timeInMilliseconds);
	};
};

export const formatTipoMovimentacao = (movimentacao) => {
	if (movimentacao == 'CREDITO') {
		return 'Crédito';
	} else if (movimentacao == 'DEBITO') {
		return 'Débito';
	}

	return 'N/A';
}

export const formatPercentageFloat = (value) => {
	console.log(value)
	if (value === '' || value == undefined) {
		return '';
	}

	if (isNaN(value)) {
		value = parseFloat(value);
	}
	
	value = value.toFixed(2).toString().replace(".", ",");
	console.log(value)
	return value;
};

export const listToleranciaCancelamento = [
	{
		value: 20,
		label: "20 minutos"
	},
	{
		value: 40,
		label: "40 minutos"
	},
	{
		value: 60,
		label: "1 hora"
	},
	{
		value: 120,
		label: "2 horas"
	},
	{
		value: 180,
		label: "3 horas"
	},
	{
		value: 240,
		label: "4 horas"
	},
	{
		value: 300,
		label: "5 horas"
	}
]

export const listDuracaoPenalidade = [
	{
		value: 0,
		label: "Sem penalidade"
	},
	{
		value: 5,
		label: "5 minutos"
	},
	{
		value: 60,
		label: "1 hora"
	},
	{
		value: 120,
		label: "2 horas"
	},
	{
		value: 180,
		label: "3 horas"
	},
	{
		value: 360,
		label: "6 horas"
	},
	{
		value: 720,
		label: "12 horas"
	},
	{
		value: 1440,
		label: "24 horas"
	},
	{
		value: 2880,
		label: "48 horas"
	},
	{
		value: 4320,
		label: "72 horas"
	}
]

export const formatValueForCSVExport = (value) => formatBRL(value).replace("R$", '').trim();

export const listaBancos = [
	"Banco ABC Brasil S.A.",
	"Banco Afinz S.A. Banco Múltiplo",
	"Banco Agibank S.A.",
	"Banco Alfa S.A.",
	"Banco Andbank (Brasil) S.A.",
	"Banco B3 S.A.",
	"Banco BANDEPE S.A.",
	"Banco BMG S.A.",
	"Banco BNP Paribas Brasil S.A.",
	"Banco BOCOM BBM S.A.",
	"Banco Bradescard S.A.",
	"Banco Bradesco BBI S.A.",
	"Banco Bradesco Financiamentos S.A.",
	"Banco Bradesco S.A.",
	"Banco BS2 S.A.",
	"Banco BTG Pactual S.A.",
	"Banco C6 Consignado S.A.",
	"Banco C6 S.A.",
	"Banco Caixa Geral - Brasil S.A.",
	"Banco Cargill S.A.",
	"Banco Cetelem S.A.",
	"Banco Cifra S.A.",
	"Banco Citibank S.A.",
	"Banco CNH Industrial Capital S.A.",
	"Banco Cooperativo Sicoob S.A.",
	"Banco Cooperativo Sicredi S.A.",
	"Banco Credit Agricole Brasil S.A.",
	"Banco Credit Suisse (Brasil) S.A.",
	"Banco CSF S.A.",
	"Banco da Amazônia S.A.",
	"Banco da China Brasil S.A.",
	"Banco Daycoval S.A.",
	"Banco de Lage Landen Brasil S.A.",
	"Banco Digimais S.A.",
	"Banco Digio S.A.",
	"Banco do Brasil S.A.",
	"Banco do Estado de Sergipe S.A.",
	"Banco do Estado do Pará S.A.",
	"Banco do Estado do Rio Grande do Sul S.A.",
	"Banco do Nordeste do Brasil S.A.",
	"Banco Fibra S.A.",
	"Banco Fidis S.A.",
	"Banco Finaxis S.A.",
	"Banco Genial S.A.",
	"Banco GM S.A.",
	"Banco Guanabara S.A.",
	"Banco IBM S.A.",
	"Banco Inbursa S.A.",
	"Banco Industrial do Brasil S.A.",
	"Banco Inter S.A.",
	"Banco Investcred Unibanco S.A.",
	"Banco Itaú BBA S.A.",
	"Banco Itaú Consignado S.A.",
	"Banco Itaú Veículos S.A.",
	"Banco ItauBank S.A",
	"Banco Itaucard S.A.",
	"Banco J. P. Morgan S.A.",
	"Banco J. Safra S.A.",
	"Banco John Deere S.A.",
	"Banco Letsbank S.A.",
	"Banco Luso Brasileiro S.A.",
	"Banco Master S.A.",
	"Banco Mercantil do Brasil S.A.",
	"Banco Mizuho do Brasil S.A.",
	"Banco Modal S.A.",
	"Banco Morgan Stanley S.A.",
	"Banco MUFG Brasil S.A.",
	"Banco Nacional de Desenvolvimento Econômico e Social - BNDES",
	"Banco Original S.A.",
	"Banco PAN S.A.",
	"Banco Paulista S.A.",
	"Banco Pine S.A.",
	"Banco Rabobank International Brasil S.A.",
	"Banco RCI Brasil S.A.",
	"Banco Rendimento S.A.",
	"Banco Rodobens S.A.",
	"Banco Safra S.A.",
	"Banco Santander (Brasil) S.A.",
	"Banco Semear S.A.",
	"Banco Senff S.A.",
	"Banco Société Générale Brasil S.A.",
	"Banco Sumitomo Mitsui Brasileiro S.A.",
	"Banco Topázio S.A.",
	"Banco Toyota do Brasil S.A.",
	"Banco Triângulo S.A.",
	"Banco Voiter S.A.",
	"Banco Volkswagen S.A.",
	"Banco Volvo Brasil S.A.",
	"Banco Votorantim S.A.",
	"Banco VR S.A.",
	"Banco Western Union do Brasil S.A.",
	"Banco XP S.A.",
	"Banco Yamaha Motor do Brasil S.A.",
	"BANESTES S.A. Banco do Estado do Espírito Santo",
	"Bank of America Merrill Lynch Banco Múltiplo S.A.",
	"BCV - Banco de Crédito e Varejo S.A.",
	"BEXS Banco de Câmbio S.A.",
	"BNY Mellon Banco S.A.",
	"Braza Bank S.A. Banco de Câmbio",
	"BRB - Banco de Brasília S.A.",
	"Caixa Econômica Federal",
	"China Construction Bank (Brasil) Banco Múltiplo S.A.",
	"Citibank N.A.",
	"Deutsche Bank S.A. - Banco Alemão",
	"Hipercard Banco Múltiplo S.A.",
	"HSBC Brasil S.A. - Banco de Investimento",
	"Itaú Unibanco S.A.",
	"JPMorgan Chase Bank, National Association",
	"Kirton Bank S.A. - Banco Múltiplo",
	"Paraná Banco S.A.",
	"Scania Banco S.A.",
	"Scotiabank Brasil S.A. Banco Múltiplo",
	"State Street Brasil S.A. - Banco Comercial",
	"Travelex Banco de Câmbio S.A.",
	"UBS Brasil Banco de Investimento S.A.",
];

export const converterDataParaFormatoAmericano = (dataStr) => {
	// Divida a string da data em partes
	const partesData = dataStr.split('/');
	const dia = partesData[0];
	const mes = partesData[1];
	const ano = partesData[2];

	// Crie uma nova string com a data no formato MM/DD/AAAA
	const dataConvertida = `${mes}/${dia}/${ano}`;

	// Extraia a hora da string original
	const hora = dataStr.split(' ')[1];

	// Combine a data e hora no novo formato
	return `${dataConvertida}`;
};

export const convertToNumber = (numberString) => {
	const cleanedString = numberString.replace(/\.(?=.*\.)/g, '');
	
	return parseFloat(cleanedString);
};
