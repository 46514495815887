import { UPDATE_BREADCRUMB } from "./breadcrumbActions"; 

const initialState = {
  breadcrumb: [],
};

export default function breadcrumbReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_BREADCRUMB:
      return {
        ...state,
        breadcrumb: action.payload,
      };
    default:
      return state;
  }
}