import React, { useState } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import * as auth from "../../store/ducks/auth.duck";
import { login } from "../../crud/auth.crud";
import * as utils from "../../../_metronic/utils/utils";
import { Constants } from "../../utils/Constants";
import { useDispatch } from "react-redux";
import { actions } from "../../store/ducks/flags.duck";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../styles/RedesignV2.scss"
import TextInputV2 from "../../components/redesign-v2/TextInputV2";
import { InputAdornment } from "@material-ui/core";
import ButtonV2 from "../../components/redesign-v2/ButtonV2";

function Login(props) {
	const [loading, setLoading] = useState(false);
	const [loadingButtonStyle, setLoadingButtonStyle] = useState({ paddingRight: "2.5rem" });

	const dispatch = useDispatch();
	const [showPassword, setShowPassword] = useState(false);

	const enableLoading = () => {
		setLoading(true);
		setLoadingButtonStyle({ paddingRight: "3.5rem" });
	};

	const disableLoading = () => {
		setLoading(false);
		setLoadingButtonStyle({ paddingRight: "2.5rem" });
	};

	const validateLogin = (values) => {
		const errors = {};

		if (!values.email) {
			errors.email = "O e-mail é uma informação obrigatória.";
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
			errors.email = "Você precisa informar um e-mail válido.";
		}

		if (!values.password) {
			errors.password = "A senha é uma informação obrigatória.";
		}

		return errors;
	};

	const onSubmitForm = async (values, setStatus, setSubmitting) => {
		enableLoading();

		await login(values.email, values.password).then(res => {
			disableLoading();

			utils.setStorage('authToken', res.data.token, null);

			localStorage.setItem('timeZone', res.data.time_zone);
			document.cookie = `authToken=${res.data.token}`;
			document.cookie = `user=${JSON.stringify(res.data.user)}`;
			window.localStorage.setItem('acesso_pump', JSON.stringify(res.data.user.empresa.emp_acesso_pump));

			window.localStorage.setItem('migrando_galax', JSON.stringify(res.data.user.empresa.emp_galax_pay_public_token ? true : false));
			window.localStorage.setItem('user_liberado_migrar', JSON.stringify(res.data.user.id == res.data.autorizado_migrar ? true : false));
			window.localStorage.setItem('migracao_analise', JSON.stringify(res.data.migracao_analise));

			if (res.data.migracao_analise || !res.data.autorizado_migrar) {
				console.log('teste0')
				dispatch(actions.setExibirCompletarConta(0));
				dispatch(actions.setRedirecionarCompletarConta(0));
				dispatch(actions.setAcessoDom(false));
			} else if(res.data.user.id == res.data.autorizado_migrar && res.data.user.empresa.emp_migracao_gateway_liberada && !res.data.user.empresa.emp_dom_pagamentos_token && !res.data.user.empresa.emp_galax_pay_public_token) {
				dispatch(actions.setExibirCompletarConta(1));
				dispatch(actions.setRedirecionarCompletarConta(1));
			} else if (res.data.user.empresa.emp_dom_pagamentos_token) {
				dispatch(actions.setAcessoDom(true));
				dispatch(actions.setExibirCompletarConta(0));
				dispatch(actions.setRedirecionarCompletarConta(0));
			} else {
				dispatch(actions.setExibirCompletarConta(1));
			}

			props.login(res.data);
		}).catch((err) => {
			disableLoading();

			setSubmitting(false);

			if (err.response?.data?.errors) {
				setStatus(err.response.data.errors[0]);
			} else {
				setStatus("Usuário ou senha incorretos.");
			}
		});
	};

	return (
		<>
			<div className="card-login" >
				<div className="card-login-container-logo" >
					<img alt="Logo" src={toAbsoluteUrl("/images/new_logo.png")} style={{ height: '40px', marginBottom: '48px' }} />
				</div>

				<h3 className="card-login-title" >Bem-vindo(a) ao Cashbarber!</h3>
				<h3 className="card-login-subtitle" >Informe suas credenciais para acessar o sistema.</h3>

				<Formik initialValues={{ email: "", password: "" }}
					validate={values => validateLogin(values)} onSubmit={(values, { setStatus, setSubmitting }) => onSubmitForm(values, setStatus, setSubmitting)}>
					{({ values, status, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
						<form noValidate={true} autoComplete="off" className="kt-form" onSubmit={handleSubmit} style={{ marginTop: '48px', marginBottom: '0px', marginLeft: '0px', marginRight: '0px' }} >
							{status ? (
								<div role="alert" className="alert alert-danger">
									<div className="alert-text">{status}</div>
								</div>
							) : null}

							<div className="form-group">
								<TextInputV2 type="email" placeholder="Informe o seu e-mail" margin="normal" className="kt-width-full" name="email"
									onBlur={handleBlur} onChange={handleChange} value={values.email} helperText={touched.email && errors.email}
									error={Boolean(touched.email && errors.email)} textLabel='E-mail' />
							</div>

							<div className="form-group">
								<TextInputV2 type={showPassword ? 'text' : 'password'} margin="normal" placeholder="Informe sua senha" className="kt-width-full" name="password"
									onBlur={handleBlur} onChange={handleChange} value={values.password} helperText={touched.password && errors.password}
									error={Boolean(touched.password && errors.password)} textLabel='Senha'
									InputProps={{
										endAdornment: (
											<InputAdornment onClick={() => setShowPassword(!showPassword)} style={{ width: 40, height: 40 }} position="end" className='d-flex justify-content-center'>
												<i className={showPassword ? 'fa fa-eye-slash' : "fa fa-eye"} style={{ color: '#8C959B', cursor: 'pointer' }} />
											</InputAdornment>
										)
									}} />
							</div>

							<div className="card-login-container-links" >
								<a className="forgot-password" href="/password/forgot" >Esqueceu sua senha?</a>
								{/* <a className="forgot-password" target='_blank' href={Constants.termosUsoURL}>Termos de uso</a>
								<a className="forgot-password" target='_blank' href={Constants.politicasPrivacidadeURL}>Políticas de privacidade</a> */}
							</div>

							<div className="card-login-container-button">
								<ButtonV2 id="kt_login_signin_submit" type="submit" disabled={isSubmitting}
									className={`btn btn-v2-primary`}
									style={loadingButtonStyle}
									text={'Acessar'}
									loading={loading}>
									
								</ButtonV2>
							</div>

							<div className="card-login-termos-politica">Ao continuar, você concorda com a <a className="forgot-password" target='_blank' href={Constants.politicasPrivacidadeURL}>Política de Privacidade</a> e os <a className="forgot-password" target='_blank' href={Constants.termosUsoURL}>Termos de uso</a></div>
						</form>
					)}
				</Formik>
			</div>
		</>
	);
}

export default connect(null, auth.actions)(Login)

